export default {
	name            : "Français",
	french          : "Français",
	english         : "Anglais",
	chinese         : "中文",
	theMovie        : "Le film",
	theBD           : "La BD",
	theBook         : "Le livre",
	theCards        : "Les cartes",
	forum           : "Forum",
	contact         : "Contact",
	downloads       : "Télécharger",
	directBDAccess  : "",
	directBookAccess: "",
	movieDotRar     : "Error404Found.rar",
	the5Wheel       : "Les cinq roues :",
	wheel0          : "Moi",
	wheel1          : "Plaisir et Douleur",
	wheel2          : "Sousfrance et Désir",
	wheel3          : "Haine et Mépris",
	wheel4          : "Toi",
    "supportUs"     : "Soutenez-nous",
	"linkSupportUs" : "https://fr.tipeee.com/error404found",
	"contactUs"     : "Contactez nous : pas pour l'instant",
	"forumTitle"    : "Forum :",
	"forumText1"    : "Vous avez vraiment cru que votre avis m’interressait !",
	"forumText2"    : "Vous pouvez discuter entre vous ",
	"members"       : "Members",
	"motivations"   : "Pourquoi ?",
	"greetings"     : "Remerciements",
	imgWheel0       : "static/000---Poster-du-livre-des-révélations.jpg",
	imgWheel1       : "static/401---Poster-de-la-carte-des-ombres.jpg",
	imgWheel2       : "static/402---Poster-du-livre-manquant.jpg",
	imgWheel3       : "static/403---Poster-du-livre-des-morts-et-du-livre-trouvé.jpg",
	imgWheel4       : "static/404---Poster-du-livre-comment-sauter.jpg",
	linkBD          : "static/The comics.pdf",
	linkBook        : "static/The book.pdf",
	linkDldAll      : "static/404Found-18.rar",
	linkMovie       : "https://player.vimeo.com/video/538274754",
	linkMovie2      : "https://player.vimeo.com/video/654059527",
	linkMembers     : "static/Membre web.pdf",
	linkMotivations : "static/Quand donc ainsi que le pré-en-bulle + plus ref BD.pdf",
	linkGreetings   : "static/remerciements web.pdf"
}