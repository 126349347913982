/* This is a virtual file generated by layer-pack */
let req, _exports = {}, walknSetExport=require('App/.___layerPackIndexUtils').walknSetExport;
const _App_ui_assets_i18n_ch_js = require("App/ui/assets/i18n/ch.js");
walknSetExport(_exports, "ch", _App_ui_assets_i18n_ch_js);
const _App_ui_assets_i18n_en_js = require("App/ui/assets/i18n/en.js");
walknSetExport(_exports, "en", _App_ui_assets_i18n_en_js);
const _App_ui_assets_i18n_fr_js = require("App/ui/assets/i18n/fr.js");
walknSetExport(_exports, "fr", _App_ui_assets_i18n_fr_js);

export const ch = _exports.ch;

export const en = _exports.en;

export const fr = _exports.fr;
export default _exports;