export default {
	language        : "中文",
	french          : "法文",
	english         : "英文",
	chinese         : "中文",
	theMovie        : "电影",
	theBD           : "漫画",
	theBook         : "书",
	theCards        : "地图",
	forum           : "论坛",
	contact         : "联系",
	downloads       : "下载",
	directBDAccess  : "",
	directBookAccess: "",
	movieDotRar     : "Error404Found.rar",
	the5Wheel       : "五个轮子 :",
	wheel0          : "我",
	wheel1          : "快樂與痛苦",
	wheel2          : "慾望與苦難",
	wheel3          : "蔑视和仇恨",
	wheel4          : "你",
	"supportUs"     : "支持我们",
	"linkSupportUs" : "https://fr.tipeee.com/error404found",
	"contactUs"     : "       联系我们 : not yet",
	"forumTitle"    : "论坛 :",
	"forumText1"    : "你以为我对你的观点 真有兴趣嘛！",
	"forumText2"    : "你们可以互相聊天！",
	"members"       : "成员",
	"motivations"   : "为什么 ？",
	"greetings"     : "感谢",
	imgWheel0       : "static/000---Poster-du-livre-des-révélations.jpg",
	imgWheel1       : "static/401---Poster-de-la-carte-des-ombres.jpg",
	imgWheel2       : "static/402---Poster-du-livre-manquant.jpg",
	imgWheel3       : "static/403---Poster-du-livre-des-morts-et-du-livre-trouvé.jpg",
	imgWheel4       : "static/404---Poster-du-livre-comment-sauter.jpg",
	linkBD          : "static/The comics.pdf",
	linkBook        : "static/The book.pdf",
	linkDldAll      : "static/404Found-18.rar",
	linkMovie       : "https://player.vimeo.com/video/538274754",
	linkMovie2      : "https://player.vimeo.com/video/654059527",
	linkMembers     : "static/Membre web.pdf",
	linkMotivations : "static/Quand donc ainsi que le pré-en-bulle + plus ref BD.pdf",
	linkGreetings   : "static/remerciements web.pdf"
}