export default {
	language        : "English",
	french          : "French",
	english         : "English",
	chinese         : "Chinese",
	theMovie        : "The Movie",
	theBD           : "The comics",
	theBook         : "The book",
	theCards        : "The maps",
	forum           : "Forum",
	contact         : "Contact",
	downloads       : "Download",
	directBDAccess  : "",
	directBookAccess: "",
	movieDotRar     : "Error404Found.rar",
	the5Wheel       : "The five wheels :",
	wheel0          : "Me",
	wheel1          : "Pleasure and Pain",
	wheel2          : "Desire and Suferring",
	wheel3          : "Contempt and Hate",
	wheel4          : "You",
	"supportUs"     : "Support us",
	"linkSupportUs" : "https://fr.tipeee.com/error404found",
	"contactUs"     : "       Contact us : not yet",
	"forumTitle"    : "Forum :",
	"forumText1"    : "Do you realy belive that your point of view realy interresting me ! !",
	"forumText2"    : "You can continue to chat with each others ! ",
	"members"       : "Members",
	"motivations"   : "Why ?",
	"greetings"     : "Greetings",
	imgWheel0       : "static/000---Poster-du-livre-des-révélations.jpg",
	imgWheel1       : "static/401---Poster-de-la-carte-des-ombres.jpg",
	imgWheel2       : "static/402---Poster-du-livre-manquant.jpg",
	imgWheel3       : "static/403---Poster-du-livre-des-morts-et-du-livre-trouvé.jpg",
	imgWheel4       : "static/404---Poster-du-livre-comment-sauter.jpg",
	linkBD          : "static/The comics.pdf",
	linkBook        : "static/The book.pdf",
	linkDldAll      : "static/404Found-18.rar",
	linkMovie       : "https://player.vimeo.com/video/538274754",
	linkMovie2      : "https://player.vimeo.com/video/654059527",
	linkMembers     : "static/Membre web.pdf",
	linkMotivations : "static/Quand donc ainsi que le pré-en-bulle + plus ref BD.pdf",
	linkGreetings   : "static/remerciements web.pdf"
}